<template>
    <div class="gutter">
        <div v-if="!done" class="registration-flow">
            <div v-if="current_step === 2 || current_step === 3" class="nav-back">
                <div class="return-button" v-on:click="goBack"><div><i class="mdi mdi-chevron-left"></i><span> Gå tillbaka </span></div></div>
            </div>
            <div class="logo-container">
                <img src="@/assets/Liberalerna.svg" title="Liberalerna" />
            </div>

            <div v-if="current_step === 1" class="step">

                <div class="description">
                    <h1 class="headline center">Välkommen som medlem i Liberalerna</h1>
                    <p class="preamble">
                        Många viktiga beslut börjar med en känsla. llskan över ofrihet, orättvisor och utsatthet är bränslet för liberalers engagemang.
                    </p>
                    <p>
                        Genom att bli medlem i Liberalerna gör du de liberala idéerna starkare. Ditt medlemskap öppnar också många olika möjligheter. Det kostar bara 50 kr första året. Därefter 300 kr/år, och för dig som är under 26 år kostar det 50 kr/år. Välkommen!
                    </p>
                </div>

                <div class="step-form">
                    <div class="content-header blue">Fyll i ditt personnummer och postnummer så hittar vi din adress automagiskt</div>
                    <div class="input-wrapper">
                        <div class="smart-field">
                            <input type="text" name="ssn" id="ssn" v-bind:class="{'error': hasError('ssn')}" ref="ssn_input" v-model.lazy="ssn" placeholder="ÅÅÅÅMMDD-NNNN" />
                            <label for="ssn" v-bind:class="{'error': hasError('ssn')}" >Personnummer</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="zip" id="zip" v-bind:class="{'error': hasError('zip')}" v-model="zip" placeholder="76112" />
                            <label for="zip" v-bind:class="{'error': hasError('zip')}" >Postnummer</label>
                        </div>
                    </div>
                </div>

            </div>


            <div v-if="searched && current_step === 2" class="step">

                <div class="description">
                    <h1 class="headline center">Om mig</h1>
                    <div v-if="full_details_edit !== true && no_ssn === false" class="card-holder">

                        <div class="content-header center">Stämmer detta?</div>
                        <div class="address-card">
                            <div class="icon">
                                <img src="@/assets/account.svg" title="Liberalerna" />
                            </div>
                            <div class="information">
                                <div class="content-header">{{ first_name }} {{ last_name }}</div>
                                <div class="sub-header">{{ address }}</div>
                                <div class="sub-header">{{ zip }} {{ city }}</div>
                                <div class="change">
                                    <a v-on:click="full_details_edit = true">Ändra</a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="step-form">
                    <div class="content-header blue" v-if="!full_details_edit && no_ssn === false">Får vi fråga dig om två saker till?</div>

                    <div v-if="!full_details_edit" class="input-wrapper">
                        <div class="smart-field">
                            <input type="text" name="email_input" id="email_input" v-bind:class="{'error': hasError('email')}" ref="email_input" v-model="email" placeholder="E-mail" />
                            <label for="email_input" v-bind:class="{'error': hasError('email')}">Mejladress</label>
                        </div>
                        <div class="smart-field">
                            <input type="text" name="mobile" id="mobile" v-bind:class="{'error': hasError('mobile')}" ref="mobile" v-model="mobile" placeholder="+123 456 78 9012" />
                            <label for="mobile" v-bind:class="{'error': hasError('mobile')}">Mobilnummer/Telefonnummer</label>
                        </div>
                    </div>
                    <div v-if="full_details_edit" class="input-wrapper">

                        <div class="smart-field" v-if="no_ssn === true">
                            <input type="text" name="birthdate" id="birthdate" v-bind:class="{'error': hasError('birthdate')}" ref="email_input" v-model="birthdate" placeholder="ÅÅÅÅMMDD" />
                            <label for="birthdate" v-bind:class="{'error': hasError('birthdate')}">Födelsedatum (ÅÅÅÅMMDD)</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="email_input" id="email_input" v-bind:class="{'error': hasError('email')}" ref="email_input" v-model="email" placeholder="E-mail" />
                            <label for="email_input" v-bind:class="{'error': hasError('email')}">Mejladress</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="mobile" id="mobile" v-bind:class="{'error': hasError('mobile')}" ref="mobile" v-model="mobile" placeholder="+123 456 78 9012" />
                            <label for="mobile" v-bind:class="{'error': hasError('mobile')}">Mobilnummer/Telefonnummer</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="first_name" id="first_name" v-bind:class="{'error': hasError('first_name')}" v-model="first_name" placeholder="Förnamn" />
                            <label for="first_name" v-bind:class="{'error': hasError('first_name')}">Förnamn</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="last_name" id="last_name" v-bind:class="{'error': hasError('last_name')}" v-model="last_name" placeholder="Efternamn" />
                            <label for="last_name" v-bind:class="{'error': hasError('first_name')}">Efternamn</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="address" id="address" v-bind:class="{'error': hasError('address')}" v-model="address" placeholder="Adress" />
                            <label for="address" v-bind:class="{'error': hasError('address')}">Adress</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="zip" id="zip" v-bind:class="{'error': hasError('zip')}" v-model="zip" placeholder="12 345" />
                            <label for="zip" v-bind:class="{'error': hasError('zip')}">Postnummer</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="city" id="city" v-bind:class="{'error': hasError('city')}" v-model="city" placeholder="Stad" />
                            <label for="city" v-bind:class="{'error': hasError('city')}">Stad</label>
                        </div>

                    </div>
                </div>
            </div>

            <div v-if="current_step === 3" class="step">
                <div class="description">
                    <h1 class="headline center">Vänligen välj ett medlemskap</h1>
                </div>
                <div class="step-form">
                    <div v-for="(item, index) in membershiptypes" v-bind:key="item.id" :index="index" class="member-card">
                        <div v-if="index === 1" class="content-header blue" >
                            Om du vill bidra lite mer så har vi perfekta val för dig:
                        </div>
                        <div class="custom-radio">
                            <input :id="'mtype_'+item.id" type="radio" name="membershiptype" v-model="membershiptype" :value="item.id" />
                            <label :for="'mtype_'+item.id">
                                {{ item.title }} ({{ item.price }} SEK)
                                <span v-if="item.description" v-html="item.description"></span>
                            </label>
                            <label :for="'mtype_'+item.id" class="radio-gfx">
                                <label :for="'mtype_'+item.id" class="circle-wrapper"></label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-navigation">

                <div v-if="current_step === 1" class="button-toolbar">
                    <button class="blue" v-on:click="noSearch">Bli medlem</button>
                    <button v-on:click="noSearch">Gå vidare utan personnummer</button>
                    <router-link class="btn" to="/login">Avbryt</router-link>
                </div>

                <div v-if="current_step == 2" class="button-toolbar">
                    <button class="blue" v-on:click="verifyMemberData">Nästan klar</button>
                </div>

                <div v-if="current_step == 3" class="button-toolbar">
                    <div id="accept-tos-wrapper">
                        <input type="checkbox" name="accept_tos" id="accept-tos" v-model="accept_tos">
                        <label for="accept-tos">Jag accepterar <a href="https://www.liberalerna.se/anvandarvillkor" target="_blank">användarvillkoren</a></label>
                    </div>
                    <button v-on:click="registerMember" class="blue" :disabled="!accept_tos" :class="{'inactive': !accept_tos}">Betala</button>
                </div>

                <div class="steps">
                    <div><div :class="(current_step === 1) ? 'circle' : ''"></div></div>
                    <div><div :class="(current_step === 2) ? 'circle' : ''"></div></div>
                    <div><div :class="(current_step === 3) ? 'circle' : ''"></div></div>
                </div>

                <div class="footer-help">
                    Kontakta <a href="mailto:support@liberalerna.se">support@liberalerna.se</a> om du behöver hjälp.
                </div>

            </div>

        </div>

        <div class="registration_result" v-if="let_pay">
            <div class="logo-container">
                <img src="@/assets/Liberalerna.svg" title="Liberalerna" />
            </div>
            <div class="description">
                <i class="mdi mdi-debug-step-over"></i>
                <h3 class="headline blue">Du är straxt medlem..</h3>
                <p>Vi skickar dig nu vidare till betalning...</p>
            </div>
        </div>

        <div class="registration_result" v-if="complete">
            <div class="logo-container">
                <img src="@/assets/Liberalerna.svg" title="Liberalerna" />
            </div>
            <div class="description" v-if="!showSwish">
                <div class="status-icon">
                    <img src="@/assets/happy.svg" title="Happy" />
                </div>
                <h3 class="headline blue">Du är nu medlem!</h3>
                <p>Du kommer att bli kontaktad av oss inom kort.</p>
            </div>
            <div class="description" v-if="showSwish">
              <div class="status-icon">
                <img src="@/assets/swish.svg" title="Swish" style="width: 100px; height: 100px;" />
              </div>
              <h3 class="headline blue">Öppna din swish app för att betala medlemsavgiften!</h3>
              <br>
              <br>
              <br>
              <button class="blue" v-on:click="cancelSwish">Avbryt</button>
            </div>
            <div class="button-toolbar">
                <button v-if="!showSwish" class="blue" v-on:click="gotoLiberalerna">Till Liberalerna.se</button>
                <button v-if="!showSwish" v-on:click="gotoLiberalernaApp">Till medlemsappen</button>
            </div>
        </div>

        <div class="registration_result" v-if="almost_member">
            <div class="logo-container">
                <img src="@/assets/Liberalerna.svg" title="Liberalerna" />
            </div>
            <div class="description">
                <div class="status-icon">
                    <img src="@/assets/wonder.svg" title="Wonder" />
<!--                    <img src="@/assets/happy.svg" title="Happy" />-->
                </div>
                <h3 class="headline blue">Något gick fel med din betalning...</h3>
                <p>Men oroa dig inte, du kan aktivera ditt medlemskap här i appen och betala medlemskapet direkt i appen under "Mina fakturor".</p>
            </div>
            <div class="button-toolbar">
                <button class="blue" v-on:click="gotoLiberalerna">Till Liberalerna.se</button>
                <button v-on:click="gotoLiberalernaActivate">Aktivera ditt medlemskonto</button>
            </div>
        </div>

        <Loader type="component" />
    </div>
</template>

<script>
    import apiCall from "@/utils/api"
    import Loader from "@/components/Loader"
    import {mapActions} from "vuex";


    export default {
        components: {
            Loader
        },
        computed: {
            hasError(field) {
                return field => {
                    for(var i in this.error_fields) {
                        if(this.error_fields[i] === field) {
                            return true;
                        }
                    }
                    return false;
                }
            }
        },
        watch: {
            birthdate(value) {
                this.birthdate = value.replace(/[^0-9]/, '');
                if(value.length === 8) {
                    this.loadMemberships();
                }
            },
            ssn(value) {
                this.error_fields = [];
                if(value.length === 12){
                    // Insert - which is probably missing
                    this.ssn = value.substr(0,8)+"-"+value.substr(8,4);
                } else if(value.length === 10 || value.length === 11){
                    this.error_fields.push("ssn");
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Fel i personnummer',
                        text: 'Du behöver ange personnummer i formatet ÅÅÅÅMMDD-NNNN',
                        duration: 10000,
                        speed: 1000
                    });
                }
                if(this.ssn.length === 13) {
                    this.loadMemberships();
                }
            }
        },
        data(){
            return {
                swishInterval: null,
                ssn: "",
                zip: "",
                first_name: "",
                last_name: "",
                email: "",
                address: "",
                city: "",
                mobile: "",
                membershiptype: "",
                birthdate: "",
                //
                current_step: 2,
                searched: true,
                let_pay: false,
                almost_member: false,
                error_fields: [],
                done: false,
                complete: false,
                membership_error: false,
                full_details_edit: true,
                no_ssn: true,
                //
                membershiptypes: [],
                //
                paid_invoice_id: 1,
                payer_agentid: null,
                payer_xml_writer: null,
                payer_data: null,
                payer_checksum: null,
                payer_charset: null,
                payer_server: null,
                accept_tos: false,
                showSwish: false
            }
        },
        mounted() {
            // newbie: this.$route.params.newbie
            if(this.$route.params.result === "payment_error"){
                this.almost_member = true;
                this.done = true;
            } else if(this.$route.params.result === "complete"){
                this.complete = true;
                this.done = true;
            }
            if(this.$refs.ssn_input !== undefined) {
                this.$refs.ssn_input.focus();
            }
        },
        methods: {
            goBack() {
                if(this.current_step === 2) {
                    this.current_step = 1;
                }
                if(this.current_step === 3) {
                    this.current_step = 2;
                }
            },
            validateEmail(email) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            scrollToTop() {
                window.scrollTo(0,0);
            },
            gotoLiberalerna() {
                document.location = 'https://www.liberalerna.se';
            },
            gotoLiberalernaApp() {
                // document.location = process.env.VUE_APP_BASEURL;
                this.$router.push("/");
            },
            cancelSwish() {
                clearInterval(this.swishInterval);
                this.showSwish = false;
                this.complete = false;
                this.done = true;
                this.almost_member = true;
            },
            gotoLiberalernaActivate() {
                this.$router.push("/login/activate");
            },
            submitPaymentForm() {
                this.$refs.payment_form.submit();
            },
            loadMemberships() {
                let data = {
                    param_birthdate: (this.no_ssn) ? this.birthdate : this.ssn.substr(0,8)
                };
                apiCall("/default/getMembershipTypes", {data}).then(res => {
                    this.membershiptypes = res;
                    this.membershiptype = res[0].id;
                });
            },
            noSearch() {
                this.searched = true;
                this.no_ssn = true;
                this.full_details_edit = true;
                this.current_step = 2;
            },
            verifyMemberData() {
                let required_data = [];
                if(this.no_ssn === true) {
                    required_data = ["first_name", "last_name", "birthdate", "email", "zip"];
                } else {
                    required_data = ["first_name", "last_name", "ssn", "email", "zip"];
                }


                let valid = true;
                this.error_fields = [];
                for(var i in required_data) {
                    if(this[required_data[i]] === "") {
                        this.error_fields.push(required_data[i]);
                        valid = false;
                    }
                }

                // Check that birthdate is correct and at least 8 characters
                if(this.no_ssn === true && this.birthdate.length < 8) {
                    this.error_fields.push("birthdate");
                    valid = false;
                }
                if(!this.validateEmail(this.email)) {
                    this.error_fields.push("email");
                    valid = false;
                }
                if(valid) {
                    this.current_step = 3;
                } else {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Du måste fylla i alla fält',
                        text: 'Kontrollera formuläret så du fyllt i alla fält.',
                        duration: 10000,
                        speed: 1000
                    });
                }
            },
            registerMember() {
                if (!this.accept_tos) {
                    return;
                }

                let required_data = [];
                if(this.no_ssn === true) {
                    required_data = ["first_name", "last_name", "birthdate", "email", "zip"];
                } else {
                    required_data = ["first_name", "last_name", "ssn", "email", "zip"];
                }
                let valid = true;
                let vm = this;
                this.error_fields = [];
                this.membership_error = false;
                for(var i in required_data) {
                    if(this[required_data[i]] === "") {
                        this.error_fields.push(required_data[i]);
                        valid = false;
                    }
                }
                if(this.membershiptype === "") {
                    this.membership_error = true;
                    valid = false;
                }
                if(!valid) {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Du måste fylla i alla fält',
                        text: 'Kontrollera formuläret så du fyllt i alla fält.',
                        duration: 10000,
                        speed: 1000
                    });
                } else {
                    let data = {
                        param_type: this.membershiptype,
                        param_first_name: this.first_name,
                        param_last_name: this.last_name,
                        param_ssn: this.ssn,
                        param_birthdate: this.birthdate,
                        param_email: this.email,
                        param_address: this.address,
                        param_city: this.city,
                        param_zip: this.zip,
                        param_mobile: this.mobile
                    };

                    this.showLoading("component");
                    apiCall("/default/newMember", {data}).then(res => {
                        this.scrollToTop();
                        this.hideLoading("component");
                        if(res.error) {
                            if(res.code === 441 || res.code === 442) {
                                this.$notify({
                                    type: 'error',
                                    group: 'general',
                                    title: 'Något gick fel',
                                    text: 'Fel: '+res.error,
                                    duration: 10000,
                                    speed: 1000
                                });
                            } else {
                                this.$notify({
                                    type: 'error',
                                    group: 'general',
                                    title: 'Något gick fel',
                                    text: 'Något blev fel när vi skulle skapa ditt medlemskap. Kontakta oss på support@liberalerna.se så hjälper vi dig.',
                                    duration: 10000,
                                    speed: 1000
                                });
                            }
                        } else {
                          // only do this if res.swish is set and not empty
                          if(res.swish && res.swish.PublicId) {
                              this.done = true;
                              this.complete = true;
                              this.showSwish = true;
                              this.almost_member = false;

                              this.swishInterval = setInterval(() => {
                                  apiCall("/default/checkSwish", {data: {param_public_id: res.swish.PublicId}}).then(res => {
                                      if(res.Status === "Declined" || res.Status === "Error") {
                                          clearInterval(this.swishInterval);
                                          this.showSwish = false;
                                          this.complete = false;
                                          this.almost_member = true;
                                      } else if(res.Status === 'Paid'){
                                          clearInterval(this.swishInterval);
                                          this.showSwish = false;
                                          this.showSwish = false;
                                          this.almost_member = false;
                                      }
                                  });
                              }, 2000);
                          } else {
                              this.done = true;
                              this.complete = false;
                              this.showSwish = false;
                              this.almost_member = true;
                          }
                        }
                    });
                }
            },
            loadAddress() {
                let data = {
                    param_ssn: (this.no_ssn === true) ? this.birthdate : this.ssn,
                    param_zip: this.zip,
                };
                this.showLoading("component");
                this.no_ssn = false;
                apiCall("/default/getPersonInfo", {data}).then(res => {
                    this.hideLoading("component");
                    if(res.status === "success") {
                        this.searched = true;
                        this.current_step = 2;
                        this.first_name = res.first_name;
                        this.last_name = res.last_name;
                        this.city = res.city;
                        this.address = res.address_1;
                        if(res.address_2 !== "") {
                            this.address += ' '+res.address_2;
                        }
                    } else {
                        this.$notify({
                            type: 'warn',
                            group: 'general',
                            title: '',
                            text: 'Något blev fel när vi försökte matcha ditt personnummer med postnumret du skrev in, men det gör inget. Du kan fylla i uppgifterna manuellt här nedanför istället.',
                            duration: 10000,
                            speed: 1000
                        });
                        this.noSearch();
                    }
                });
            },
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            })
        }
    }
</script>
