<template>
    <div class="create_post" v-if="hasAccess || groupMode">
        <Modal ref="modal_add" @closed="resetStates" @opened="showTree = true">
            <template slot="header">
                <div class="header">
                    {{ creationText }} {{ typeText }}
                </div>
            </template>
            <template slot="body">
                <form id="create_post_form">
                    <AnchorLinkModal v-if="showAnchorLinkModal" @close="showAnchorLinkModal = false" @submit="appendLink($event)"/>

                    <div class="form-title" v-if="createType !== 'file'">
                        <div class="post-icon">
                            <img :src="avatarUrl" />
                        </div>
                        <div class="inputs">
                            <div class="smart-field" >
                                <input type="text" name="title" id="title"  :class="{errorInput: error.title === true}" ref="title" v-model="title" placeholder=" " v-bind:disabled="busy" />
                                <label for="title" :class="{errorInput: error.title === true}" class="mandatory">Rubrik</label>
                            </div>

                            <resizable-textarea ref="post_content_area" v-if="!editing && (createType === 'event' || createType === 'post') ">
                                <textarea id="textarea" rows="1" v-model="text" :placeholder="createType === 'event' ? 'Skriv din beskrivning här...' : 'Skriv ditt innehåll här...'" v-bind:disabled="busy"></textarea>
                            </resizable-textarea>

                            <textarea id="textarea" v-if="editing && (createType === 'event' || createType === 'post') " class="editing-textarea" v-model="text"
                                      :placeholder="createType === 'event' ? 'Skriv din beskrivning här...' : 'Skriv ditt innehåll här...'" v-bind:disabled="busy"></textarea>

                            <div v-if="createType === 'event' || createType === 'post'">
                                <i class="mdi mdi-format-bold button tiny" title="Gör text fet" v-on:click="formatSelectedText('bold')"></i>
                                <i class="mdi mdi-format-italic button tiny" title="Gör text kursiv" v-on:click="formatSelectedText('italic')"></i>
                                <i class="mdi mdi-link-variant button tiny" title="Infoga länk" @click="showAnchorLinkModal = true"></i>
                            </div>
                        </div>
                    </div>

                    <div class="create-event" v-if="createType === 'event'">

                        <div class="content-header blue">Typ</div>
                        <div class="select-holder">
                            <select v-model="event_type" >
                                <option value="meeting">Möte</option>
                                <option value="campaign">Kampanj</option>
                            </select>
                        </div>

                        <div class="content-header blue">Tid</div>

                        <div class="fullday-holder">
                            <div class="switch-input">
                                <label class="switch">
                                    <input id="fullDay" type="checkbox" v-model="fullDay">
                                    <span class="slider"></span>
                                </label>
                                <label for="fullDay">Heldag</label>
                            </div>
                        </div>

                        <div class="datepicker">
                            <label for="event-from" class="mandatory" :class="{errorInput: error.start === true}">Från</label>
                            <datetime id="event-from" v-model="start" :type="dateType" :min-datetime="today" :class="{errorInput: error.start === true}" placeholder="Välj när eventet startar" :minute-step="10" :format="dateFormat" auto></datetime>
                        </div>

                        <div class="datepicker">
                            <label for="event-to" class="mandatory" :class="{errorInput: error.end === true}">Till</label>
                            <datetime id="event-to" v-model="end" :type="dateType" :min-datetime="minDate" :class="{errorInput: error.end === true}" placeholder="Välj när eventet slutar" :minute-step="10" :format="dateFormat" auto></datetime>
                        </div>

                        <div class="content-header blue">Plats</div>

                        <div class="smart-field">
                            <input type="text" name="location_adress" id="location_address" ref="location_address" v-model="location_address" placeholder=" " v-bind:disabled="busy" />
                            <label for="location_address">Adress</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="location_zip" id="location_zip" ref="location_zip" v-model="location_zip" placeholder=" " v-bind:disabled="busy" />
                            <label for="location_zip">Postnummer</label>
                        </div>

                        <div class="smart-field">
                            <input type="text" name="location_city" id="location_city" ref="location_city" v-model="location_city" placeholder=" " v-bind:disabled="busy" />
                            <label for="location_city">Stad</label>
                        </div>

                    </div>


                    <div class="share-controls" v-if="createType !== 'folder'">
                        <!--<div class="sharing" v-if="createType == 'post' && 1 === 2">
                            &lt;!&ndash; TODO: Hide this until it works &ndash;&gt;
                            <a class="button" v-on:click="add_shared = 'file'"><i class="mdi mdi-plus"></i><i class="mdi mdi-file"></i> Fil från filarkivet</a>
                            <a class="button" v-on:click="add_shared = 'calendar'"><i class="mdi mdi-plus"></i><i class="mdi mdi-calendar"></i> Event från kalender</a>
                        </div>
                        <div class="add_shared" v-if="add_shared != null">
                            <div class="input-label">
                                <span v-if="add_shared == 'file'">Fil från filarkivet</span>
                                <span v-if="add_shared == 'calendar'">Event från kalender</span>
                            </div>
                            <div class="shared_search_input">
                                <input type="text" v-model="shared_searcher" placeholder="Sök här.." />
                            </div>
                            <div class="shared_tools">
                                <input type="button" value="Lägg till" />
                                <input type="button" v-on:click="add_shared = null" value="Avbryt" />
                            </div>
                        </div>-->
                        <file-upload @removeUploadedFile="removeUploadedFile" :edit="id !== null" :uploadedFiles="uploadedfiles"
                                     ref="files" v-if="createType !== 'folder'" :type="createType" ></file-upload>
                    </div>

                    <!--TODO: Lägg till "short" för areas och medlemsantal -->
                    <div class="areas-wrapper" v-if="postEventObject && createType === 'post'">
                        <div class="post-event">
                            <div class="post-event-header">
                                <div class="content-header">Bifogat event</div>
                                <button class="warning" @click.prevent="removePostEvent">Ta bort</button>
                            </div>
                            <CalendarItem :eventID="postEventObject.id" postEvent/>
                        </div>
                    </div>

                    <div class="areas-wrapper" v-if="!groupMode && editingGroup === null && !editingGroup">
                        <div class="content-header" v-if="availableAreas.length === 1 && mySubscriptionGroups.length <= 0"><span class="capitalize">{{thisTypeText}}</span> {{typeText}} postas till medlemmar i</div>
                        <div class="areas" v-if="availableAreas.length === 1 && mySubscriptionGroups.length <= 0">
                            <div class="area">
                                <div class="area-inner">
                                    <div class="short-icon">
                                        <div>NT</div>
                                    </div>
                                    <div class="area-content">
                                        <div class="area-title">{{ availableAreas[0].name }}</div>
                                        <div class="area-members"><!-- 65 Medlemmar --></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="content-header" v-if="availableAreas.length !== 1 || (availableAreas.length === 1 && mySubscriptionGroups.length >= 1)">Posta {{thisTypeText}} {{typeText}} till medlemmar i</div>
                        <template v-if="availableAreas.length !== 1 || (availableAreas.length === 1 && mySubscriptionGroups.length >= 1)">
                            <div class="areas" v-for="(item, index) in availableAreas" :key="index + 'areas'">
                                <div class="custom-radio">
                                    <input type="checkbox" class="checkbox" :id="'area-' + item.name + '[' + index + ']'" v-model="selectedAreas" :value="item.area" />
                                    <label :for="'area-' + item.name + '[' + index + ']'">
                                        <div class="area-title">{{ item.name }}</div>
                                        <div class="area-members"><!-- 65 Medlemmar --></div>
                                    </label>
                                    <label :for="'area-' + item.name + '[' + index + ']'" class="radio-gfx">
                                        <label :for="'area-' + item.name + '[' + index + ']'" class="circle-wrapper"></label>
                                    </label>
                                </div>
                            </div>
                        </template>
                        <template v-if="createType === 'post'">
                            <div class="areas" v-for="(item, index) in mySubscriptionGroups" :key="index + 'sgroup'">
                                <div class="custom-radio">
                                    <input type="radio" class="radio" :id="'sgroup-' + item.id" v-model="sgroup" :value="item.id" />
                                    <label :for="'sgroup-' + item.id">
                                        <div class="area-title">{{ item.name }}</div>
                                        <div class="area-members">{{ item.description }}</div>
                                    </label>
                                    <label :for="'sgroup-' + item.id" class="radio-gfx">
                                        <label :for="'sgroup-' + item.id" class="circle-wrapper"></label>
                                    </label>
                                </div>
                            </div>
                        </template>

                     </div>

                    <div class="areas-wrapper" v-if="groupMode">
                        <div class="content-header"><span class="capitalize">{{thisTypeText}}</span> {{typeText}} postas till medlemmar i</div>
                        <div class="areas">
                            <div class="area">
                                <div class="area-inner">
                                    <div class="short-icon">
                                        <div>{{group.short}}</div>
                                    </div>
                                    <div class="area-content">
                                        <div class="area-title">{{ group.title }}</div>
                                        <div class="area-members">{{ group.members }} Medlemmar</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="areas-wrapper" v-if="editingGroup !== null && editingGroup">
                        <div class="content-header"><span class="capitalize">{{thisTypeText}}</span> {{typeText}} postas till medlemmar i</div>
                        <div class="areas">
                            <div class="area">
                                <div class="area-inner">
                                    <div class="area-content">
                                        <div class="area-title">{{ editingGroup.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <folder-tree v-if="createType === 'folder' || createType === 'file' && showTree" :area="area" :type="createType" :newItem="true" @folderSelect="setFolder($event)" :currentFolder="currentFolder"/>

                    <div class="publish-as-holder" v-if="(createType === 'post' || createType === 'event') && myUserToPublishAs.length > 0">
                        <span>Publicera&nbsp;som:&nbsp;</span>
                        <select v-model="publish_as">
                            <option value="">Mig själv</option>
                            <option v-for="(person, index) in myUserToPublishAs" :key="'person' + index" :value="person.member_id">{{ person.name }}</option>
                        </select>
                    </div>

                    <div class="allow-comments-holder" v-if="createType === 'event' || createType === 'post'">
                        <div class="switch-input">
                            <label class="switch">
                                <input id="allow_comments" type="checkbox" v-model="allow_comments">
                                <span class="slider"></span>
                            </label>
                            <label for="allow_comments">Tillåt kommentarer</label>
                        </div>
                    </div>

                    <div class="status-holder" v-if="createType === 'event' || createType === 'post'">
                        <div class="switch-input">
                            <label class="switch">
                                <input id="status" type="checkbox" @change="setStatus($event)" :checked="status === 'published'">
                                <span class="slider"></span>
                            </label>
                            <label for="status">Publicera</label>
                        </div>
                    </div>

                    <div class="status-holder" v-if="createType === 'post' && sgroup">
                        <div class="switch-input" :class="[(!canSendExternal) ? 'disabled' : '']">
                            <label class="switch">
                                <input id="external" type="checkbox" @change="setSendExternal($event)" :checked="sgroup !== null && external === true" :disabled="!canSendExternal">
                                <span class="slider"></span>
                            </label>
                            <label for="external">Skicka till extern lista</label>
                        </div>
                    </div>

                    <div class="datepicker" v-show="status === 'draft'" v-if="createType === 'event' || createType === 'post'">
                        <label for="publish-date">Publiceringsdatum</label>
                        <datetime id="publish-date" v-model="publish_date" zone="local" value-zone="local" :type="dateType" :min-datetime="mintime" placeholder="Välj när posten ska publiceras" :minute-step="10" :format="dateFormat" auto></datetime>
                    </div>

                    <div class="allow-comments-holder" v-if="!editing && (createType === 'file' || createType === 'event')">
                        <div class="switch-input">
                            <label class="switch">
                                <input id="allow_notifications" type="checkbox" v-model="allow_notifications">
                                <span class="slider"></span>
                            </label>
                            <label for="allow_notifications">Skicka aviseringar</label>
                        </div>
                    </div>


                    <div class="allow-comments-holder" v-if="!editing && createType === 'event'">
                        <div class="switch-input">
                            <label class="switch">
                                <input id="allowAttend" type="checkbox" v-model="allowAttend">
                                <span class="slider"></span>
                            </label>
                            <label for="allowAttend">Tacka ja/nej</label>
                        </div>
                    </div>

                    <!-- TODO: tags -->
                    <!--<div class="tags">-->
                        <!--<input type="text" @keyup="searchTags" v-model="tagSearchText" v-if="createType === 'post'">-->
                        <!--<template v-for="tag in tags" >-->
                            <!--<div :key="tag.id">-->
                                <!--<span class="tag_name" @click="chooseTag(tag.name)">#{{tag.name}}</span>-->
                            <!--</div>-->
                        <!--</template>-->
                    <!--</div>-->
                </form>
                <transition name="fade">
                    <div class="gutter" v-if="folderError" >
                        <p class="error">
                            {{folderError}}
                        </p>
                    </div>
                </transition>
                <div class="create_submit">
                    <button class="blue" form="create_post_form" type="submit" value="Spara" v-on:click.prevent="add" v-bind:disabled="busy"><i class="mdi mdi-send"></i>{{submitText}}</button>
                </div>
            </template>

            <template slot="footer">
            </template>

        </Modal>

        <div class="create_button">
            <div class="dropdown-wrapper">
                <div class="icon" v-on:click="toggleForm"><img src="../assets/plus.svg" /></div>
                <div class="dropdown" v-if="showChoices">
                    <ul class="more">
                        <li @click="openForm('folder')"><i class="mdi mdi-folder"></i>Skapa Mapp</li>
                        <li @click="openForm('file')"><i class="mdi mdi-file"></i>Ladda upp fil</li>
                    </ul>
                </div>
            </div>
        </div>
        <Loader type="component"/>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import apiCall from "@/utils/api";
    import {Datetime} from 'vue-datetime';
    import FileUpload from "@/components/FileUpload";
    import Modal from "./CreateModal";
    import Loader from "./Loader";
    import FolderTree from "@/components/FolderTree";
    import ResizableTextarea from '@/utils/ResizableTextarea';
    import { mixin as clickaway } from 'vue-clickaway';
    import CalendarItem from '@/components/CalendarItem';
    import AnchorLinkModal from "@/components/AnchorLinkModal";
    import moment from 'moment';
    import some from 'lodash/some';

    export default {
        name: "Form",
        components: {
            Loader,
            Modal,
            datetime: Datetime,
            FileUpload,
            FolderTree,
            ResizableTextarea,
            CalendarItem,
            AnchorLinkModal
        },
        watch: {
            sgroup: function(val) {
                if(val > 0) {
                    this.area = "";
                }
                this.external = false;
            },
            selectedAreas: {
                handler: function(val) {
                    this.area = val[0];
                },
                deep: true
            },
            area: function(val) {
                if(val > 0) {
                    this.sgroup = null;
                }
                this.external = false;
            },
            publish_date: function(val) {
                if(moment(val).format("YYYY-MM-DD HH:mm:ss") < moment(this.mintime).format("YYYY-MM-DD HH:mm:ss")) {
                    this.publish_date = moment(this.mintime).format("YYYY-MM-DDTHH:mm:ssZ");
                }
            }
        },
        props: {
            postEvent: {
              type: Object,
                default: function() {}
            },
            multipleChoices: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "post"
            },
            addAction: {
                type: Function,
                default: function() {}
            },
            editAction: {
                type: Function,
                default: function() {}
            },
            currentFolder: {
                type: Object,
                default: () => {}
            },
            currentArea: {
                type: String,
                default: null
            },
            currentFolderStandard: {
                type: Boolean,
                default: false
            },
            currentFolderParent: {
                type: String,
                default: null
            }
        },
        computed: {
            canSendExternal() {
                return this.sgroup &&
                       this.mySubscriptionGroups &&
                       this.mySubscriptionGroups.length &&
                       some(this.mySubscriptionGroups, (group) => {
                           return group.id === this.sgroup && group.show_external === '1';
                       });
            },
            mintime() {
                let time = moment().add(20, 'm');
                let minutes = Math.ceil(time.minute() / 10) * 10;
                return time.format('YYYY-MM-DDTHH:' + minutes + ':00Z');
            },
            showCreateButton(){
                return this.hasAccess || this.groupMode || (this.mySubscriptionGroups && this.mySubscriptionGroups.length);
            },
            publish() {
                return this.status === 'published';
            },
            creationText(){
                if(this.createType === "file"){
                    return "Ladda upp";
                }
                else if(this.editing){
                    return "Redigera";
                } else {
                    return "Skapa"
                }
            },
            dateType(){
              if(this.fullDay){
                  return "date";
              }
              return "datetime";
            },
            dateFormat(){
                let basicDate = "dd/MM"
                if(!this.fullDay){
                    return basicDate + " HH:mm";
                } return basicDate;
            },
            today() {
                return new Date().toISOString();
            },
            minDate(){
                if(this.start.length > 0){
                    return this.start;
                }
                return this.today;
            },
            avatarUrl(){
                if(this.createType === "post"){
                    return this.baseUrl + 'avatar/id/' + this.userID;
                } else {
                    return require("@/assets/" + this.createType + "-mini.svg");
                }
            },
            groupMode() {
                let current_group = this.$store.getters['user/currentGroup'];
                if(current_group.id > 0) {
                    return true;
                } else {
                    return false
                }
            },
            group() {
                let current_group = this.$store.getters['user/currentGroup'];
                if(current_group.id > 0) {
                    return current_group;
                }
                return null;
            },
            groupId() {
                let current_group = this.$store.getters['user/currentGroup'];
                if(current_group.id > 0) {
                    return current_group.id;
                } else {
                    return false
                }
            },
            submitText() {
                return this.busy ? 'Vänta...' : 'Spara ' + this.typeText;
            },
            typeText() {
                switch (this.createType) {
                    case "file":
                        return "fil";
                    case "event":
                        return "evenemang";
                    case "folder":
                        return "mapp";
                    default:
                        return "inlägg";
                }
            },
            thisTypeText() {
                switch (this.createType) {
                    case "file":
                        return "denna";
                    case "event":
                        return "detta";
                    case "folder":
                        return "denna";
                    default:
                        return "detta";
                }
            },
            availableAreas(){
                let type = this.createType;
                if(type === 'folder'){
                    type = 'file';
                }
                return this.$store.getters['user/availableAreas'](type);
            },
            mySubscriptionGroups() {
                return this.$store.getters["user/mySubscriptionGroups"];
            },
            myUserToPublishAs() {
                return this.$store.getters["user/myUserToPublishAs"];
            },
            hasAccess(){
                return this.$store.getters['user/hasAccessToType'](this.createType);
            },
            userID(){
                return this.$store.getters["user/userData"].id;
            },
            pluralName(){
                return this.createType === "file" ? "filer" : "mappar";
            },
            ...mapState({
                user_data: state => state.user.data
            })
        },
        mixins: [clickaway],
        data() {
            return {
                postEventObject: this.postEvent || null,
                id: null,
                show_form: false,
                busy: false,
                loading: false,
                title: "",
                text: "",
                start: "",
                end: "",
                location_address: "",
                location_zip: "",
                location_city: "",
                area: "1",
                selectedAreas: [],
                allow_comments: true,
                status: "published",
                external: false,
                // publish_date: moment().format("YYYY-MM-DDTHH:mm:ss.000Z"),
                publish_date: "",
                allow_notifications: true,
                showChoices: false,
                createType: this.type,
                uploadedfiles: [],
                selectedFolder: {
                    id: null,
                    standard: false
                },
                add_shared: null,
                shared_searcher: "",
                tags: [],
                chosenTags: [],
                tagSearchText: "",
                showTree: false,
                error: {
                    title: false,
                    location_zip: false,
                    location_city: false,
                    location_address: false,
                    start: false,
                    end: false
                },
                editing: false,
                event_type: "meeting",
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                sgroup: null,
                folderError: "",
                fullDay: false,
                publish_as: "",
                link: null,
                editingGroup: null,
                showAnchorLinkModal: false,
                allowAttend: true
            }
        },
        methods: {
            setStatus(event) {
                if(event.target.checked) {
                    this.status = 'published';
                } else {
                    this.status = 'draft';
                }
            },
            setSendExternal(event) {
                if(event.target.checked) {
                    this.external = true;
                } else {
                    this.external = false;
                }
            },
            appendLink({ text, link }){
                const textarea =  document.getElementById("textarea");
                const cursor = textarea.selectionEnd;
                this.showAnchorLinkModal = false;
                text = '[' + text + ']';
                link += text;
                this.text = [this.text.slice(0, cursor), link, this.text.slice(cursor)].join('');
            },
            removePostEvent(){
                this.postEventObject = null;
            },
            resetStates(){
                this.showTree = false;
                this.title = "";
                this.publish_as = "";
                if(this.createType !== "folder"){
                    this.$refs.files.resetFiles();
                }
            },
            setFolder(folder){
                this.folderError = "";

                this.selectedFolder = {
                    id: folder.id,
                    standard: folder.data.standard
                };

            },
            validateFields(){
                if(this.title.trim() === "" && this.createType !== "file" && this.createType !== "event"){
                    this.error.title = true;
                    return false;
                }
                else if(this.createType === "file"){
                    if(this.$refs.files.getFilesData().length <= 0){
                        this.folderError = "Välj en fil";
                        return false;
                    }
                    if(parseInt(this.currentFolder.id) === 0 && this.selectedFolder.id === null){
                        this.folderError = "Välj en mapp";
                        return false;
                    }

                    return true;
                }
                else if(this.createType === "event") {
                    // Create array of error keys and loop through them to look for empty fields
                    let eventInputs = Object.entries(this.error).map(item => (item[0]));
                    let errors = [];
                    let optional = ["location_zip","location_city","location_address"];
                    for(let eventInput of eventInputs){
                        if(optional.indexOf(eventInput) !== -1) {
                            continue;
                        }
                        if(this[eventInput].trim() === ""){
                            this.error[eventInput] = true;
                            errors.push(eventInput);
                        }
                    }
                    return errors.length <= 0;
                } else {
                    return true;
                }
            },
            validateFolder(item){
                if(parseInt(this.currentFolder.id) === 0 && this.selectedFolder.id === null){
                    this.folderError = "Välj en mapp";
                    return false;
                }

                if(this.selectedFolder.event_folder === true && this.createType === "folder"){
                    this.folderError = "Du kan inte lägga mappar i den här mappen";
                    return false;
                }

                if(this.currentFolder.standard === true || this.currentFolderStandard === true || this.selectedFolder.standard === true){
                    this.folderError = "Du har inte behörighet att lägga " + this.pluralName + " i den här mappen";
                    return false;
                }
                 else {
                     //Validates
                    let parentFolder = this.createType === "folder" ? "parent" : "folder";
                    if(this.selectedFolder.id === null){
                        item[parentFolder] = this.currentFolder.id;
                        return item;
                    } else {
                        item[parentFolder] = this.selectedFolder.id;
                        return item;
                    }
                }

            },
            add() {

                if(!this.validateFields()){
                    return;
                }

                let group = null;
                if(this.groupMode){
                    group = this.groupId;
                } else if(this.editingGroup !== null){
                    group = this.editingGroup.id;
                } else if(this.sgroup !== null && this.sgroup > 0) {
                    group = this.sgroup;
                    this.area = 0;
                } else if (this.availableAreas.length === 1 && this.selectedAreas.length === 0){
                  this.selectedAreas.push(this.availableAreas[0].area);
                }

                let item = {
                        id: this.id,
                        area: this.selectedAreas,
                        title: this.createType === 'file' ? this.$refs.files.getFilesData()[0].name : this.title,
                        text: this.text,
                        start: this.start,
                        end: this.end,
                        location_address: this.location_address,
                        location_zip: this.location_zip,
                        location_city: this.location_city,
                        type: this.createType,
                        allow_comments: this.allow_comments,
                        status: this.status,
                        publish_date: (this.status === 'draft' ? moment(this.publish_date).format("YYYY-MM-DD HH:mm:00") : ''),
                        allow_notifications: this.allow_notifications,
                        comments: 0,
                        files: this.createType === 'folder' ? [] : this.$refs.files.getFilesData(),
                        group: group,
                        send_external: this.external,
                        event_type: this.event_type,
                        publish_as: this.publish_as,
                        event_id: (this.createType === 'post' && this.postEventObject) ? this.postEventObject.id : null,
                        link: this.link,
                        allow_attend: this.allowAttend ? "1" : "0",
                        current_files: this.uploadedfiles.length
                };

                if(this.createType === "file" || this.createType === "folder"){
                    /**
                     * Validate the selected folder, returns false if not validated, returns the complete
                     * item with parent folder inserted if validated
                     * */
                    if(!this.validateFolder(item)){
                        return;
                    } else {
                       item = this.validateFolder(item);
                    }
                }

                this.busy = true;
                this.showLoading("component");
                this.$store.dispatch("items/addItem", [ this.createType, item, this.editing, group, this.postEventObject ]).then(res => {
                    this.editAction(res);
                    this.busy = false;
                    this.title = '';
                    this.text = '';
                    this.start = '';
                    this.end = '';
                    this.allow_comments = true;
                    this.status = "published";
                    // this.publish_date = moment().format("YYYY-MM-DDTHH:mm:ss.000Z");
                    this.publish_date = "";
                    this.allow_notifications = true;
                    this.selectedFolder ={
                        id: null,
                        standard: false
                    };
                    this.folderError = "";
                    this.hideLoading("component");
                    this.$refs.modal_add.close();

                });

            },
            removeUploadedFile(fileID) {
                // if(confirm("Tänk på att du tar bort filen permanent från systemet. Är du säker?")) {
                    this.uploadedfiles = this.uploadedfiles.filter(file => file.id !== fileID);
                    let data = {
                        id: fileID
                    };
                    apiCall("/item/deleteFile", {data}).then(res => {
                        //console.log(res)
                    });
                // }
            },
            toggleForm(item) {
                this.createType = this.type;
                this.folderError = "";
                this.sgroup = null;
                this.error = {
                    title: false,
                    location_zip: false,
                    location_city: false,
                    location_address: false,
                    start: false,
                    end: false
                };
                this.link = null;
                this.selectedFolder ={
                    id: null,
                    standard: false
                };

                this.editingGroup = null;
                this.editing = false;
                this.postEventObject = null;

                this.external = false;

                if(item.id) {
                    /**
                     * Item is being edited
                     */
                    if(item.group > 0){
                        this.editingGroup = {
                            id: item.group,
                            title: item.area_name
                        };
                    }
                    this.link = item.link;
                    this.editing = true;
                    this.id = item.id;
                    this.area = item.area;
                    this.title = item.title;
                    this.text = item.text;
                    this.start = item.start;
                    this.end = item.end;
                    if(item.real_publisher > 0) {
                        this.publish_as = item.user;
                    }
                    this.event_type = item.event_type;
                    this.allow_comments = item.allow_comments;
                    this.status = item.status;
                    this.publish_date = moment(item.publish_date).format("YYYY-MM-DDTHH:mm:ss.000Z");
                    this.allow_notifications = item.allow_notifications;
                    this.location_address = item.location_address;
                    this.location_zip = item.location_zip;
                    this.location_city = item.location_city;
                    this.uploadedfiles = item.files.concat(item.images);
                    if(item.type === "event"){
                        if(item.start.includes("T00:00:00.000Z") && item.start.includes("T00:00:00.000Z")){
                            this.fullDay = true;
                        }
                    }
                    if(item.main_image) {
                        this.uploadedfiles.push(item.main_image);
                    }
                    if(item.event_info){
                        this.postEventObject = item.event_info;
                    } else {
                        this.postEventObject = null;
                    }
                } else {
                    this.id = null;
                    if(this.groupMode !== true){
                        if(typeof this.availableAreas === "object" && this.availableAreas.length > 0){
                            if(this.currentArea !== null){
                                this.area = this.currentArea;
                            } else {
                                this.area = this.availableAreas[0].area;
                            }
                        } else {
                            this.area = 1;
                        }
                    } else {
                        this.area = null;
                    }
                    this.title = "";
                    this.text = "";
                    this.start = "";
                    this.end = "";
                    this.event_type = "meeting";
                    this.allow_comments = true;
                    this.status = "published";
                    // this.publish_date = moment().format("YYYY-MM-DDTHH:mm:ss.000Z");
                    this.publish_date = "";
                    this.allow_notifications = true;
                    this.location_address = "";
                    this.location_zip = "";
                    this.location_city = "";
                    this.uploadedfiles = [];
                }
                if(this.multipleChoices === true){
                    this.showChoices = !this.showChoices;
                } else {
                    this.$refs.modal_add.showModal();
                }
            },
            openForm(type){
                this.createType = type;
                this.showChoices = false;

                this.$refs.modal_add.showModal();
            },
            formatSelectedText(type) {
                if(type === "bold") {
                    this.$refs.post_content_area.wrapWith('**');
                } else if(type === "italic") {
                    this.$refs.post_content_area.wrapWith('~');
                }
            },
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            })
        },
        mounted(){
            if(this.groupMode === true){
                this.area = null;
            } else if(this.postEventObject !== null){
                this.area = this.postEventObject.area;
                if(this.postEventObject.group > 0){
                    this.editingGroup = {
                        id: this.postEventObject.group,
                        title: this.postEventObject.area_name
                    };
                }
            }
        }
    }
</script>
