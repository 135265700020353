<template>
    <div class="user-data">
        <Loader type="component"/>
        <div class="user-profile">
            <div class="image-wrapper">
                <img :src="getAvatarUrl" />
                <div class="change-avatar-trigger" v-on:click="change_avatar = !change_avatar" title="Byt profilbild">
                    <i class="mdi mdi-account-box-multiple" />
                </div>
            </div>
            <div class="user-profile-inner">
                <div class="id">#{{ user_data.id }}</div>
                <div class="user-name">{{ user_data.first_name }} {{ user_data.middle_name }} {{ user_data.last_name }}</div>
                <div class="user-main-area">Din förening: {{ user_main_area }}</div>
                <div class="user-memberships">
                    <div v-for="membership in user.memberships" :key="membership">
                       Nuvarande medlemskapstyp: {{ membership }}
                    </div>
                    <div v-if="user.next_membership">
                      <div v-if="!change_membership">
                         Nästa medlemskapstyp: {{ user.next_membership.title }} <button v-on:click="change_membership = true">Ändra</button>
                      </div>
                      <div class="change-membership-type" v-else>
                        <div v-if="available_memberships && available_memberships.length">
                          <div class="select-holder">
                            <label for="membershiptype">Välj medlemskap</label>
                          <select v-model="switch_to_type">
                            <option v-for="item in available_memberships" :key="item.id" :value="item.id">{{ item.title }} ({{ item.second_year_price }} SEK)</option>
                          </select>
                        </div>
                          <button v-on:click="changeMembershiptype">Spara</button>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="change-avatar" v-if="change_avatar">
            <h3 class="sub-head">Byt profilbild</h3>
            <p>Ladda upp din nya profilbild nedan. Det måste vara en bild av typen jpg och inte större än 5mb.</p>
            <file-upload ref="files" max_files="1"></file-upload>
            <div class="change-avatar-tools">
                <input type="button" value="Byt profilbild" v-on:click="changeAvatar" />
                <input type="button" value="Avbryt" v-on:click="change_avatar = !change_avatar" />
            </div>
        </div>

        <div>
            <h3 class="sub-head" @click="toggleSection('info')">
                Info
                <span class="cursor-pointer">
                    <i class="mdi mdi-plus" v-show="!sections.info"></i>
                    <i class="mdi mdi-minus" v-show="sections.info"></i>
                </span>
            </h3>
            <!--<div v-show="!sections.info" @click="toggleSection('info')">-->
                <!--<label for="email">Mejl</label>-->
                <!--<div>{{ user.email }}</div>-->
                <!--<label for="telephone">Telefon</label>-->
                <!--<div>{{ user.telephone }}</div>-->
                <!--<label for="mobilephone">Mobiltelefon</label>-->
                <!--<div>{{ user.mobilephone }}</div>-->
            <!--</div>-->
            <vue-slide-up-down :active="sections.info" ref="upDownEl">
                <div >
                    <label for="email">Mejl</label>
                    <input type="text" id="email" v-model="user.email" />
                    <label for="telephone">Telefon</label>
                    <input type="text" id="telephone" v-model="user.telephone" />
                    <label for="mobilephone">Mobiltelefon</label>
                    <input type="text" id="mobilephone" v-model="user.mobilephone" />
                    <button @click="updateMemberData" class="blue">Spara</button>
                </div>
            </vue-slide-up-down>


        </div>
        <div>
            <h3 class="sub-head" @click="toggleSection('address')">
                Adress
                <span class="cursor-pointer">
                    <i class="mdi mdi-plus" v-show="!sections.address"></i>
                    <i class="mdi mdi-minus" v-show="sections.address"></i>
                </span>
            </h3>
            <!--<div v-show="!sections.address" @click="toggleSection('address')">-->
                <!--<label for="address_address">Adress</label>-->
                <!--<div>{{ user.address.address }}</div>-->
                <!--<label for="address_address">C/o</label>-->
                <!--<div>{{ user.address.co }}</div>-->
                <!--<label for="address_zip">Postnummer</label>-->
                <!--<div>{{ user.address.zip }}</div>-->
                <!--<label for="address_city">Ort</label>-->
                <!--<div>{{ user.address.city }}</div>-->
            <!--</div>-->
            <vue-slide-up-down :active="sections.address" ref="upDownEl">
                <div>
                    <div v-if="user.address.restricted === true">
                        Du har valt att inte ha någon adress hos oss. Kontakta oss om du önskar få post.
                    </div>
                    <div v-if="user.address.restricted !== true">
                        <label for="address_address" v-bind:class="{ red: !user.address.address }">Adress *</label>
                        <input type="text" id="address_address" v-bind:class="{ empty: !user.address.address }" v-model="user.address.address" />
                        <label for="address_co">C/o</label>
                        <input type="text" id="address_co" v-model="user.address.co" />
                        <label for="address_zip" v-bind:class="{ red: !user.address.zip }">Postnummer *</label>
                        <input type="text" id="address_zip" v-bind:class="{ empty: !user.address.zip }" v-model="user.address.zip" />
                        <label for="address_city" v-bind:class="{ red: !user.address.city }">Ort *</label>
                        <input type="text" id="address_city" v-bind:class="{ empty: !user.address.city }" v-model="user.address.city" />
                        <p v-bind:class="{ red: !user.address.address || !user.address.zip || !user.address.city}" class="small">* Obligatoriska fält</p>
                        <button @click="addAddress" v-bind:class="{ blue: user.address.address && user.address.zip && user.address.city}">Spara</button>
                    </div>
                </div>
            </vue-slide-up-down>
        </div>
        <div>
            <h3 class="sub-head" @click="toggleSection('password')">
                Lösenord
                <span class="cursor-pointer">
                    <i class="mdi mdi-plus" v-show="!sections.password"></i>
                    <i class="mdi mdi-minus" v-show="sections.password"></i>
                </span>
            </h3>

            <vue-slide-up-down :active="sections.password" ref="upDownEl">
                <label for="password_current"  v-bind:class="{ red: !password.current }">Nuvarande lösenord</label>
                <input type="password" id="password_current" v-model="password.current" v-bind:class="{ empty: !password.current }" />
                <label for="password_new" v-show="sections.password" v-bind:class="{ red: !password.new }">Nytt lösenord</label>
                <input type="password" id="password_new" v-model="password.new" v-bind:class="{ empty: !password.new }" />
                <label for="password_new2" v-show="sections.password" v-bind:class="{ red: !password.new2 }">Nytt lösenord igen</label>
                <input type="password" id="password_new2" v-model="password.new2"  v-bind:class="{ empty: !password.new2 }" />
                <button @click="setNewPassword"  v-bind:class="{ blue: password.current && password.new && password.new2}">Spara</button>
            </vue-slide-up-down>
        </div>
        <div>
            <h3 class="sub-head clickable" @click="toggleSection('devices')">
                Inloggade enheter
                <i :class="(sections.devices) ? 'mdi mdi-minus' : 'mdi mdi-plus'"></i>
            </h3>
            <vue-slide-up-down :active="sections.devices" ref="upDownEl">
                <div>
                    <p>Enheterna nedan är inloggade på ditt konto. Upptäcker du någon enhet som inte borde vara inloggad kan du logga ut dom nedan, och då bör du också byta lösenord.</p>
                    <div class="device-list">
                        <div v-for="(device, index) in devices" :key="index" :item="device">
                            <div class="device">
                                <div class="unit-head">Enhetstyp</div>
                                <div class="unit-data">{{ device.user_agent }}</div>
                                <div class="unit-head">IP-adress</div>
                                <div class="unit-data">{{ device.client_ip }}</div>
                                <div class="unit-head">Senast inloggad</div>
                                <div class="unit-data">{{ device.last_used }}</div>
                                <div class="device-footer" @click="logoutDevice(device.device_id)">
                                    <div class="remove">Logga ut enhet</div>
                                    <div class="mdi mdi-close"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vue-slide-up-down>
        </div>
    </div>
</template>

<script>

    import {mapActions, mapState} from "vuex";
    // import Form from "./Form";
    import apiCall from "@/utils/api";
    import Loader from "@/components/Loader";
    // import store from '@/store/'
    import FileUpload from "@/components/FileUpload";
    import { debounce } from "debounce";
    import VueSlideUpDown from 'vue-slide-up-down'

    export default {
        name: "UserData",
        components: {
            Loader,
            FileUpload,
            VueSlideUpDown
        },
        data: function() {
            return {
                baseUrl: process.env.VUE_APP_API_DOMAIN+"backend/",
                avatar_no_chache: Math.random(),
                avatar: [],
                change_avatar: false,
                change_membership: false,
                available_memberships: [],
                switch_to_type: 0,
                sections: {
                    info: false,
                    address: false,
                    password: false,
                    devices: false
                },
                user: {
                    email: '',
                    address: '',
                    telephone: '',
                    mobilephone: '',
                    restricted: ''
                },
                password: {
                    current: '',
                    new: '',
                    new2: ''
                },
                devices: [],
            }
        },
        // store : store,
        computed: {
            user_main_area() {
                for(var i in this.user_data.areas) {
                    if(this.user_data.areas[i].primary_area === true) {
                        return this.user_data.areas[i].name;
                    }
                }
                return "Okänd";
            },
            getAvatarUrl() {
                return this.baseUrl + 'avatar/id/' + this.user_data.id+"?"+this.avatar_no_chache;
            },
            userID(){
                return this.user_data.id;
            },
            ...mapState({
                user_data: state => state.user.data
            })
        },
        created() {
            this.updateMemberData = debounce(this.updateMemberData, 1000);
            this.loadLoggedInDevices();
        },
        methods: {
            ...mapActions({
                showLoading: 'loader/show',
                hideLoading: 'loader/hide',
            }),
            async loadAvailableMemberships() {
              let data = {
                param_member_no: this.user_data.id
              };
              this.showLoading('component');
              await apiCall("/default/getMembershipTypes", {data}).then(res => {
                this.available_memberships = res;
              });
              this.hideLoading('component');
            },
            loadLoggedInDevices() {
                apiCall("/user/getLoggedInDevices").then(res => {
                    this.devices = res;
                });
            },
            logoutDevice(id) {
                if(confirm('Är du säker? Detta kommer logga ut dig på alla dina enheter (inklusive denna) för att förhindra att enheten kan logga in igen.')) {
                    apiCall("/user/logoutDevice", {
                        data: {
                            id
                        }
                    }).then(res => {
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Utloggad',
                            text: 'Enheten är nu utloggad. Du kommer snart att bli utloggad.',
                            duration: 10000,
                            speed: 1000
                        });
                        this.loadLoggedInDevices();
                    });
                }
            },
            toggleSection(section) {
                var vm = this
                Object.keys(vm.sections).forEach(function(key) {
                    if(key !== section) {
                        vm.sections[key] = false;
                    }
                });
                if(section !== '') {
                    vm.sections[section] = !vm.sections[section];
                }
            },
            changeAvatar() {
                this.showLoading("component");
                this.change_avatar = !this.change_avatar;
                let data = { file: this.$refs.files.getFilesData() };
                apiCall("/user/changeAvatar", { data }).then(res => {
                    this.hideLoading("component");
                    if(res === true) {
                        this.avatar_no_chache = Math.random();
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Bild uppdaterad',
                            text: 'Din profilbild är nu uppdaterad.',
                            duration: 10000,
                            speed: 1000
                        });
                    } else {
                        this.$notify({
                            type: 'error',
                            group: 'general',
                            title: 'Fel vid uppladdning',
                            text: 'Det gick tyvärr inte att ladda upp din bild, vänligen prova med en annan bild. Kolla att det verkligen är jpg format och att bilden inte är jättestor.',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
          async changeMembershiptype() {
              let data = {
                'member_no': this.user_data.id,
                'membership_type': this.switch_to_type
              };

            await apiCall("/user/changeMembershiptype", {data}).then(res => {
              this.user.next_membership.id = res.preferred_membershiptype_id;
              this.user.next_membership.title = res.preferred_membershiptype_title;
              this.change_membership = !this.change_membership;
              this.$notify({
                type: 'success',
                group: 'general',
                title: 'Nästa periods medlemskap är uppdaterat',
                text: '',
                duration: 10000,
                speed: 1000
              });
            });
          },
            async getMemberData () {
                let data = {
                    id: this.user_data.id
                };

                await apiCall("/user/getMemberDetails", { data }).then(res => {
                    this.user = res;
                });
            },
            updateMemberData () {
                let data = [this.user];
                apiCall("/user/changeMemberDetails", { data }).then(res => {
                    if (!res.error) {
                        this.$notify({
                            type: 'success',
                            group: 'general',
                            title: 'Uppgifterna är uppdaterade',
                            text: '',
                            duration: 10000,
                            speed: 1000
                        });
                    }
                });
            },
            addAddress () {
                if(this.user.address.address && this.user.address.zip && this.user.address.city) {
                    let data = [this.user.address];
                    data[0]["member_no"] = this.user_data.id;
                    apiCall("/user/addAddress", {data}).then(res => {
                        if (!res.error) {
                            this.$notify({
                                type: 'success',
                                group: 'general',
                                title: 'Addressen är uppdaterad',
                                text: '',
                                duration: 10000,
                                speed: 1000
                            });
                        } else {
                            this.$notify({
                                type: 'error',
                                group: 'general',
                                title: 'Addressen kunde inte uppdateras',
                                text: 'Se till att samtliga obligatoriska fält är ifyllda och försök igen',
                                duration: 10000,
                                speed: 1000
                            });
                        }
                    });
                } else {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Se till att samtliga obligatoriska fält är ifyllda',
                        text: '',
                        duration: 10000,
                        speed: 1000
                    });
                }
            },
            setNewPassword () {
                if(this.password.current && this.password.new && this.password.new2) {
                    let data = [this.password];
                    data[0]["member_no"] = this.user_data.id;
                    apiCall("/user/setNewPassword", {data}).then(res => {
                        this.password = {
                            current: '',
                            new: '',
                            new2: ''
                        };
                        if (res.changed_password) {
                            this.toggleSection();
                            this.$notify({
                                type: 'success',
                                group: 'general',
                                title: 'Lösenordet är uppdaterat',
                                text: '',
                                duration: 10000,
                                speed: 1000
                            });
                        } else {
                            this.$notify({
                                type: 'error',
                                group: 'general',
                                title: res.error,
                                text: '',
                                duration: 10000,
                                speed: 1000
                            });
                        }
                    });
                } else {
                    this.$notify({
                        type: 'error',
                        group: 'general',
                        title: 'Se till att samtliga fält är ifyllda',
                        text: '',
                        duration: 10000,
                        speed: 1000
                    });
                }
            }
        },
        mounted() {
            this.getMemberData();
            this.loadAvailableMemberships();
        }
    }

</script>
